<template>
    <div>
        <div class="contact">
            <div class="text-h5 font-weight-bold text-center">{{ $t('Page.Contact.Tit') }}</div>
            <div class="mt-10">
                <div class="font-weight-medium">{{ $t('Page.Contact.desc') }}</div>
                <div class="text-body-2 mt-5">
                    <div>{{ $t('Page.Contact.desc1') }}</div>
                    <div class="mt-3">{{ $t('Page.Contact.desc2') }}</div>

                    <div class="content-width mt-5 mt-sm-10">
                        <div class="font-weight-bold">{{ $t('Page.Contact.Q1') }}:*</div>
                        <div class="content-width">
                            <v-radio-group v-model="radio" row mandatory>
                                <v-radio :label="$t('Page.Contact.Radio1')" value="radio-1" class="mb-4"></v-radio>
                                <v-radio :label="$t('Page.Contact.Radio2')" value="radio-2" class="mb-4"></v-radio>
                                <v-radio :label="$t('Page.Contact.Radio5')" value="radio-5" class="mb-4"></v-radio>
                            </v-radio-group>
                        </div>

                        <div class="mt-4 mt-md-8">
                            <div class="font-weight-bold">{{ $t('Page.Contact.Q2') }}*</div>
                            <v-text-field outlined label="" class="mt-2"></v-text-field>
                        </div>
                        <div class="mt-4 mt-md-8">
                            <div class="font-weight-bold">{{ $t('Page.Contact.Q3') }}*</div>
                            <v-text-field outlined label="" class="mt-2"></v-text-field>
                        </div>
                        <div class="mt-4 mt-md-8">
                            <div class="font-weight-bold">{{ $t('Page.Contact.Q4') }}*</div>
                            <v-text-field outlined label="" class="mt-2"></v-text-field>
                        </div>
                        <div class="mt-4 mt-md-8">
                            <div class="font-weight-bold">{{ $t('Page.Contact.Q5') }}*</div>
                            <v-text-field outlined label="" class="mt-2"></v-text-field>
                        </div>
                        <div class="mt-4 mt-md-8">
                            <div class="font-weight-bold">{{ $t('Page.Contact.Q6') }}*</div>
                            <v-textarea outlined label="" class="mt-2"></v-textarea>
                        </div>
                        <v-btn href="partnerships@supercells.pro" target="_blank" depressed width="260" min-height="50" color="#AC8250" class="mt-5 mt-md-10">
                            <div class="text-h6 white--text">{{ $t('Page.Contact.BtnText') }}</div>
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
  </template>
  
  <script>
  import Footer from '../components/Footer.vue'
  
  export default {
    components: {
        Footer
    },
    data: () => ({
        radio: null,
    })
  }
  </script>
  
  <style>
  .contact {
    min-height: calc(100vh - 289px);
    padding: 80px 120px;
    background: #FCF9F6;
  }

  .content-width {
    max-width: 80%;
  }
  
  @media screen and (max-width: 960px) {
    .contact {
      padding: 30px 24px;
    }

    .content-width {
        max-width: 100%;
    }
  }
  </style>
  